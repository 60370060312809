import React from "react"
import { Cookies } from "../Cookies/Cookies"
import { DarkMode } from "../DarkMode/DarkMode"

export const GlobalWrapper: React.FC<{children: any}> = (props) => {
  return (
    <DarkMode>
      {props.children}
      <Cookies />
    </DarkMode>
  )
}
