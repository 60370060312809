import React from "react"
import { connector, ContainerProps } from "./containers/DarkMode.containers"

export const Wrapper: React.FC<{ dark: boolean }> = (props) => {
  return (
    <div className={`${props.dark ? "dark" : ""} antialiased`}>
      {props.children}
    </div>
  )
}

export const Container: React.FC<ContainerProps> = (props) => (
  <Wrapper {...props} />
)

export const DarkMode = connector(Container)
