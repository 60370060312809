import React from "react"
import { PageProps } from "gatsby"
import { useIntl } from "react-intl"
import { useLocation } from "@reach/router"
import { Seo } from "../components/Seo/Seo"
import { Footer } from "../components/Footer/Footer"
import { GlobalWrapper } from "../components/GlobalWrapper/GlobalWrapper"
import { CheckIcon, XMarkIcon } from "@heroicons/react/20/solid"
import clsx from "clsx"
import { ArrowLeftIcon } from "@heroicons/react/20/solid"
import { Link } from "../components/Link/Link"
import { getRefundPolicyUrl, navigateToHome } from "../i18n/utils/getUrl"
import { FormattedMessage } from "../components/FormattedMessage/FormattedMessage"
import { ITranslations } from "../interfaces/ITranslations"

type Props = PageProps<
  {},
  {
    langKey: string
    level: string
    canonicals: Array<{
      lang: string
      url: string
      isDefault: boolean
      label: string
    }>
  }
>

const tiers: {
  id: string
  name: ITranslations["keys"]
  description: ITranslations["keys"]
  highlight: boolean
  priceMonthly: string
  features: ITranslations["keys"][]
}[] = [
  {
    id: "free",
    name: "pricing/prices/free/label",
    highlight: false,
    priceMonthly: "0€",
    description: "pricing/prices/free/description",
    features: [
      "pricing/features/no-ads",
      "pricing/features/no-heart",
      "pricing/features/more-emeralds",
      "pricing/features/history",
      "pricing/features/support",
    ],
  },
  {
    id: "premium",
    name: "pricing/prices/premium/label",
    highlight: true,
    priceMonthly: "4,99€",
    description: "pricing/prices/premium/description",
    features: [
      "pricing/features/no-ads",
      "pricing/features/no-heart",
      "pricing/features/more-emeralds",
      "pricing/features/history",
      "pricing/features/support",
    ],
  },
]

function Pricing() {
  const intl = useIntl()

  return (
    <div className="bg-slate-900">
      <div className="relative overflow-hidden pt-32 pb-96 lg:pt-40">
        <div className="absolute top-0 left-0 p-4">
          <Link
            url={navigateToHome("en")}
            className="p-4 inline-block rounded-md bg-violet-500 bg-opacity-0 hover:bg-opacity-100"
          >
            <ArrowLeftIcon className="h-6 w-6 text-white" />
          </Link>
        </div>
        <div>
          <img
            className="absolute bottom-0 left-1/2 w-[1440px] max-w-none -translate-x-1/2"
            src="/pricing/grid-blur-purple-on-black.jpeg"
            alt="background"
          />
        </div>
        <div className="relative mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-4xl">
            <img src="/chest/ultimate/open.svg" className="mx-auto" />

            <p className="mt-8 text-4xl font-bold tracking-tight text-white">
              <FormattedMessage id="pricing/title" />
            </p>

            <p className="mt-6 text-lg leading-8 text-white/60">
              <FormattedMessage id="pricing/description" />
            </p>
          </div>
        </div>
      </div>
      <div className="flow-root bg-white pb-32 lg:pb-40">
        <div className="relative -mt-80">
          <div className="relative z-10 mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2 lg:gap-8">
              {tiers.map((tier) => (
                <div
                  key={tier.name}
                  className={clsx(
                    "flex flex-col rounded-3xl bg-white border-4",
                    tier.highlight
                      ? "shadow-xl border-violet-600"
                      : "border-slate-200"
                  )}
                >
                  <div className="p-8 sm:p-10">
                    <span
                      className={clsx(
                        "inline-block py-1 px-3 text-xs font-display leading-5 transition-all  ease-in-out duration-300 font-medium uppercase rounded-full",
                        tier.id === "free"
                          ? "bg-slate-200 text-slate-600"
                          : "bg-violet-500 text-white"
                      )}
                    >
                      <FormattedMessage id={tier.name} />
                    </span>
                    <div className="mt-4 font-display flex items-baseline text-5xl font-bold tracking-tight text-slate-900">
                      {tier.priceMonthly}
                      <span className="text-lg font-semibold leading-8 tracking-normal text-slate-500">
                        /mo
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-slate-600">
                      <FormattedMessage id={tier.description} />
                    </p>
                  </div>
                  <div className="flex flex-1 flex-col p-2">
                    <div className="flex flex-1 flex-col justify-between rounded-2xl bg-slate-50 p-6 sm:p-8">
                      <ul role="list" className="space-y-6">
                        {tier.features.map((feature) => (
                          <li key={feature} className="flex items-start">
                            {tier.id === "free" && (
                              <div className="flex-shrink-0">
                                <XMarkIcon
                                  className="h-6 w-6 text-red-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            {tier.id === "premium" && (
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="h-6 w-6 text-emerald-500"
                                  aria-hidden="true"
                                />
                              </div>
                            )}
                            <p
                              className={clsx(
                                "ml-3 font-display text-sm leading-6 text-slate-600",
                                tier.id === "free" && "line-through"
                              )}
                            >
                              <FormattedMessage id={feature} />
                            </p>
                          </li>
                        ))}
                      </ul>
                      {tier.id === "premium" && (
                        <div className="mt-8">
                          <button
                            onClick={() => {
                              const url =
                                "https://sudoku-academy.lemonsqueezy.com/checkout/buy/f5b1f7dc-8f5f-4dd3-8053-c26f53a4670e?embed=1&checkout[email]=marques.developpement@gmail.com&test-mode=1&logo=0"
                              window.LemonSqueezy.Url.Open(url)
                            }}
                            className="inline-block shadow-btn-2 shadow-violet-600 border-2 border-violet-600 font-display w-full rounded-lg bg-violet-500 px-4 py-3 text-center font-semibold text-white hover:bg-violet-700"
                          >
                            <FormattedMessage id="pricing/prices/buy" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="relative mx-auto mt-8 max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-md lg:max-w-4xl">
            <div className="flex flex-col gap-6 rounded-3xl p-8 ring-1 ring-slate-900/10 sm:p-10 lg:flex-row lg:items-center lg:gap-8">
              <div className="lg:min-w-0 lg:flex-1">
                <h3 className="text-lg font-semibold leading-8 tracking-tight text-violet-600">
                  <FormattedMessage id="pricing/refund/title" />
                </h3>
                <div className="mt-2 text-base leading-7 text-slate-600">
                  <FormattedMessage id="pricing/refund/description" />
                </div>
              </div>
              <div>
                <Link
                  url={getRefundPolicyUrl(intl.locale)}
                  className="inline-block rounded-lg shadow-btn-2 shadow-violet-100 border-2 border-violet-100 bg-violet-50 px-4 py-2.5 text-center text-sm font-semibold leading-5 text-violet-700 hover:bg-violet-100"
                >
                  <FormattedMessage id="pricing/refund/button" />{" "}
                  <span aria-hidden="true">&rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const PremiumPage: React.FC<Props> = (props) => {
  const intl = useIntl()

  return (
    <GlobalWrapper>
      <Seo
        title={intl.formatMessage({
          id: `pricing/seo/title`,
        })}
        description={intl.formatMessage({
          id: `pricing/seo/title`,
        })}
        lang={props.pageContext.langKey}
        langUrls={props.pageContext.canonicals}
        index={false}
        paddle
      />

      <div className="relative min-h-screen overflow-hidden">
        <Pricing />
      </div>

      <Footer lang={props.pageContext.langKey} />
    </GlobalWrapper>
  )
}

export default PremiumPage
