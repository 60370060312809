import React from "react"
import { Link } from "gatsby"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import urls from "../../i18n/urls/levels.json"
import lessons from "../../i18n/urls/lessons.json"
import levels from "../../constants/levels.json"
import {
  getBlogUrl,
  getPremiumUrl,
  getRefundPolicyUrl,
  getTermsOfServiceUrl,
} from "../../i18n/utils/getUrl"
import { Obfuscated } from "../Obfuscated/Obfuscated"
import MailIcon from "@heroicons/react/24/outline/EnvelopeIcon"

export const Footer: React.FC<{ lang: string; isIndex?: boolean }> = (
  props
) => {
  return (
    <footer className="bg-white relative print:hidden border-t border-slate-200 mt-8">
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8 ">
        <div className="grid grid-cols-1 gap-20 md:grid md:grid-cols-3 md:gap-8 font-display">
          <div>
            <div className="text-sm font-semibold tracking-wider text-slate-900 uppercase  ">
              <FormattedMessage id="footer.levels" />
            </div>
            <ul className="mt-4 space-y-4 list">
              {levels.map((label) => (
                <li key={label}>
                  <Link
                    to={
                      props.lang === "en"
                        ? `/${urls[props.lang][label]}/`
                        : `/${props.lang}/${urls[props.lang][label]}/`
                    }
                    className="text-base text-slate-900 capitalize   hover:underline"
                  >
                    {urls[props.lang][label]}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <div className="text-sm font-semibold tracking-wider text-slate-900 uppercase  ">
              <FormattedMessage id="footer.learn" />
            </div>
            <ul className="mt-4 space-y-4 list">
              <li>
                <Obfuscated
                  enabled={!props.isIndex}
                  to={
                    props.lang === "en"
                      ? `/${lessons[props.lang].index}/`
                      : `/${props.lang}/${lessons[props.lang].index}/`
                  }
                  className="text-base text-slate-900 capitalize   hover:underline"
                >
                  <FormattedMessage id="lessons.title" />
                </Obfuscated>
              </li>

              <li>
                <Obfuscated
                  enabled={!props.isIndex}
                  to={getBlogUrl(props.lang)}
                  className="text-base text-slate-900 capitalize   hover:underline"
                >
                  <FormattedMessage id="blog/articles/categories/label" />
                </Obfuscated>
              </li>
            </ul>
          </div>

          <div>
            <div className="text-sm font-semibold tracking-wider text-slate-900 uppercase  ">
              <FormattedMessage id="footer.terms-and-privacy" />
            </div>
            <ul className="mt-4 space-y-4 list">
              <li>
                <Obfuscated
                  enabled
                  to={`/privacy/`}
                  className="text-base text-slate-900 capitalize   hover:underline"
                >
                  <FormattedMessage id="privacy" />
                </Obfuscated>
              </li>
              <li>
                <Obfuscated
                  enabled
                  to={getTermsOfServiceUrl(props.lang)}
                  className="text-base text-slate-900 capitalize hover:underline"
                >
                  <FormattedMessage id="footer/terms-of-service" />
                </Obfuscated>
              </li>
              <li>
                <Obfuscated
                  enabled
                  to={getRefundPolicyUrl(props.lang)}
                  className="text-base text-slate-900 capitalize hover:underline"
                >
                  <FormattedMessage id="footer/refund" />
                </Obfuscated>
              </li>
              <li>
                <Obfuscated
                  enabled
                  to={`/cookies/`}
                  className="text-base text-slate-900 capitalize   hover:underline"
                >
                  <FormattedMessage id="cookies" />
                </Obfuscated>
              </li>
              <li>
                <Obfuscated
                  enabled
                  to={getPremiumUrl(props.lang)}
                  className="text-base text-slate-900 capitalize   hover:underline"
                >
                  <FormattedMessage id="footer/pricing" />
                </Obfuscated>
              </li>
            </ul>
          </div>
        </div>

        <div className="pt-8 mt-8 border-t border-slate-200 md:flex md:items-center md:justify-between">
          <p className="text-base text-center text-slate-900">
            &copy; 2023 Sudoku Academy. <FormattedMessage id="footer.rights" />
          </p>
        </div>
      </div>
    </footer>
  )
}
