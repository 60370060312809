import React, { ReactElement, ReactNode } from "react"
import { Link, navigate } from "gatsby"

export const Obfuscated: React.FC<{
  to: string
  className?: string
  enabled?: boolean
  children: ReactElement | ReactNode
}> = (props) => {
  if (props.enabled)
    return (
      <span
        onClick={() => navigate(props.to)}
        className={(props.className || "") + " cursor-pointer"}
        children={props.children}
      ></span>
    )

  return (
    <Link to={props.to} className={props.className} children={props.children} />
  )
}
